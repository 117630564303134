import React from 'react';

// Logo

const Logo = (): JSX.Element => {
  return (
    <a href="/" className="flex items-center group">
      <span className="mr-1 lg:mr-3">
        <svg height="2.2rem" viewBox="0 0 53 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_35:4)">
            <path
              d="M16.9353 1.05997C18.3486 -0.353323 20.6391 -0.353323 22.0524 1.05997L37.9276 16.9376C39.3409 18.3509 39.3409 20.6389 37.9276 22.0498L22.0524 37.9274C20.6391 39.3407 18.3511 39.3407 16.9402 37.9274L1.06018 22.0522C0.724068 21.7168 0.45741 21.3184 0.275469 20.8798C0.0935286 20.4411 -0.00012207 19.971 -0.00012207 19.4961C-0.00012207 19.0213 0.0935286 18.5511 0.275469 18.1125C0.45741 17.6738 0.724068 17.2754 1.06018 16.94L16.9353 1.05997ZM20.3467 2.76566C20.1205 2.53949 19.8138 2.41243 19.4939 2.41243C19.174 2.41243 18.8672 2.53949 18.641 2.76566L2.76344 18.6408C2.53726 18.867 2.4102 19.1738 2.4102 19.4937C2.4102 19.8135 2.53726 20.1203 2.76344 20.3465L18.641 36.2241C18.8672 36.4503 19.174 36.5774 19.4939 36.5774C19.8138 36.5774 20.1205 36.4503 20.3467 36.2241L36.2243 20.3465C36.4505 20.1203 36.5776 19.8135 36.5776 19.4937C36.5776 19.1738 36.4505 18.867 36.2243 18.6408L20.3467 2.76323V2.76566Z"
              fill="#19BFEC"
            />
            <rect
              x="1.3924"
              y="19.9387"
              width="26.2284"
              height="25.435"
              rx="2"
              transform="rotate(-45 1.3924 19.9387)"
              fill="#19BFEC"
            />
          </g>
          <g clipPath="url(#clip1_35:4)">
            <path
              className="group-hover:fill-white duration-300"
              d="M30.9353 1.05997C32.3486 -0.353323 34.6391 -0.353323 36.0524 1.05997L51.9276 16.9376C53.3409 18.3509 53.3409 20.6389 51.9276 22.0498L36.0524 37.9274C34.6391 39.3407 32.3511 39.3407 30.9402 37.9274L15.0602 22.0522C14.7241 21.7168 14.4574 21.3184 14.2755 20.8798C14.0935 20.4411 13.9999 19.971 13.9999 19.4961C13.9999 19.0213 14.0935 18.5511 14.2755 18.1125C14.4574 17.6738 14.7241 17.2754 15.0602 16.94L30.9353 1.05997ZM34.3467 2.76566C34.1205 2.53949 33.8138 2.41243 33.4939 2.41243C33.174 2.41243 32.8672 2.53949 32.641 2.76566L16.7634 18.6408C16.5373 18.867 16.4102 19.1738 16.4102 19.4937C16.4102 19.8135 16.5373 20.1203 16.7634 20.3465L32.641 36.2241C32.8672 36.4503 33.174 36.5774 33.4939 36.5774C33.8138 36.5774 34.1205 36.4503 34.3467 36.2241L50.2243 20.3465C50.4505 20.1203 50.5776 19.8135 50.5776 19.4937C50.5776 19.1738 50.4505 18.867 50.2243 18.6408L34.3467 2.76323V2.76566Z"
              fill="#ABB9DB"
            />
          </g>
          <defs>
            <clipPath id="clip0_35:4">
              <rect width="38.9873" height="38.9873" fill="white" />
            </clipPath>
            <clipPath id="clip1_35:4">
              <rect width="38.9873" height="38.9873" fill="white" transform="translate(14)" />
            </clipPath>
          </defs>
        </svg>
      </span>
      <span className="sm:text-base lg:text-lg font-bold group-hover:text-white transition duration-300">
        Sadra Khosravi
      </span>
    </a>
  );
};
export default Logo;
